$(() => {
  const newsCreatorBlock = $(".news-creator");
  if (newsCreatorBlock.length < 1) 
    return;

  if (document.getElementById("files")) {
    document
      .getElementById("files")
      .addEventListener("change", handleFileSelect, false);
  }

  $("body").delegate(".news-creator__form", "submit", function (event) {
    event.preventDefault();

    var formData = new FormData($(this)[0]);
    var err = [];
    var fileInput = $.trim($("#files").val() as string);

    if ($.trim($("#titel").val() as string) == "") {
      err.push("Du mangler at udfylde titel");
    }

    if ($.trim($("#content").val() as string) == "") {
      err.push("Du mangler at udfylde brødtekst");
    }
    if (fileInput != "") {
      // console.log(fileInput);
      if (!fileInput.match(/.(jpg|jpeg|png|gif)$/i)) {
        err.push("Den valgte fil er ikke et billede");
      }
    }

    if ($.trim($("#StandardImage").val() as string) == "") {
      err.push("Du mangler at vælge et billede");
    }

    if (err.length < 1) {
      const dataToPass = $(this).serialize();
      $("#content").val(nl2br($("#content").val(), false));
      $.ajax({
        url: "/api/news/CreateNewspage/",
        data: dataToPass,
        dataType: "json",

        type: "POST",
        success: function (data) {
          console.log(data);
          if (data.Result == "2") {
            $(".errorMsg").remove();
            $(".news-creator__form").before(
              "<div class='col-sm-12 alert-danger errorMsg'><b>Der er sket en fejl:</b><ul><li>Du har ikke rettigheder til at uploade filer</li></ul></div>"
            );
            $("html,body").animate(
              {
                scrollTop: $(".errorMsg").offset().top,
              },
              "fast"
            );
          } else if (data.Result == "3") {
            $(".errorMsg").remove();
            $(".news-creator__form").before(
              "<div class='col-sm-12 alert-danger errorMsg'><b>Der er sket en fejl:</b><ul><li>Nyheden kunneikke oprettes, prøv igen senere</li></ul></div>"
            );
            $("html,body").animate(
              {
                scrollTop: $(".errorMsg").offset().top,
              },
              "fast"
            );
          } else {
            //console.log(formData);
            //console.log(data);

            window.location.href = data.Url;
          }
        },
      });

      //event.stopPropagation();
      //   return true;
    } else {
      event.preventDefault();
      var errorMsg = "";
      err.forEach(function (val) {
        errorMsg += "<li>" + val + "</li>";
      });
      $(".errorMsg").remove();
      $(".news-creator__form").before(
        "<div class='col-sm-12 alert-danger errorMsg'><b>Der skete en fejl:</b><ul>" +
          errorMsg +
          "</ul></div>"
      );
      $("html,body").animate(
        {
          scrollTop: $(".errorMsg").offset().top,
        },
        "fast"
      );

      return false;
    }
  });

  $("body").delegate(".new_news", "click", function () {
    location.reload();
    return false;
  });

  $("body").delegate(".news-creator__choosable", "click", function () {
    $("#list").html(
      '<img class="thumb" src="' +
        $(this).attr("src") +
        '" /><input type="hidden" name="StandardImage" id="StandardImage" value="' +
        $(this).data("contentid") +
        '"/>'
    );
    $("#files").val(null);

    return false;
  });
});

function handleFileSelect(evt) {
  var files = evt.target.files; // FileList object
  $("#list").html(null);

  // Loop through the FileList and render image files as thumbnails.
  for (var i = 0, f; (f = files[i]); i++) {
    // Only process image files.
    if (!f.type.match("image.*")) {
      continue;
    }

    var reader = new FileReader();

    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        // Render thumbnail.
        var span = document.createElement("span");
        span.innerHTML = [
          '<img class="thumb" src="',
          e.target.result,
          '" title="',
          escape(theFile.name),
          '"/>',
        ].join("");
        $("#list").html(span);
      };
    })(f);

    // Read in the image file as a data URL.
    reader.readAsDataURL(f);
  }
}

function nl2br(str, is_xhtml) {
  var breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
}
